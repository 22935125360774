import { ApplicationConfiguration } from 'components/abp/models'
import { AuthModel } from 'domain/Auth'
import { atom } from 'recoil'
import { AVANTEA_SESSION } from 'remote/Constants'

const storageKey = localStorage.getItem(AVANTEA_SESSION)
const initSession = { access_token: '' }
const session: AuthModel | null = JSON.parse(storageKey || JSON.stringify(initSession))

export const authAtom = atom({
	key: 'auth',
	default: session,
})

export const appConfigAtom = atom({
	key: 'appConfigAtom',
	default: { status: 'loading' } as ApplicationConfiguration.Response,
})
