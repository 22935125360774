import { selector, selectorFamily } from 'recoil'
import { appConfigAtom, authAtom } from './atoms'

export const isLoggedSelector = selector({
	key: 'isLoggedSelector',
	get: ({ get }) => {
		const auth = get(authAtom)
		return auth !== null && auth.access_token !== ''
	},
})

export const createGrantedPolicySelector = selectorFamily({
	key: 'createGrantedPolicySelector',
	get:
		(permissions: string[]) =>
		({ get }) => {
			const appConfig = get(appConfigAtom)
			if (!permissions || permissions.length === 0) {
				return true
			}

			return (
				permissions.findIndex((permission) => {
					return appConfig?.auth?.grantedPolicies.hasOwnProperty(permission)
				}) > -1
			)
		},
})
