import { ApplicationConfiguration } from 'components/abp/models'
import CONFIG from 'config'
import { getEnvVars } from 'Environment'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { appConfigAtom, authAtom } from 'recoil/Login/atoms'
import { useAxiosWrapper } from './APIs'
import { AVANTEA_SESSION } from '../remote/Constants'
import { getResponse } from './Utils'

export { useLoginRemote }
const { oAuthConfig } = getEnvVars()

function useLoginRemote() {
	const api = useAxiosWrapper()
	const setAuth = useSetRecoilState(authAtom)
	const setAppConfig = useSetRecoilState(appConfigAtom)

	const history = useHistory()

	const getAppConfig = async () => {
		try {
			const appConfig = await api.get(CONFIG.APP_CONFIG).then(getResponse)
			setAppConfig({ ...appConfig, status: 'success' })
		} catch (err) {
			setAppConfig({ status: 'error' } as ApplicationConfiguration.Response)
		}
	}

	const login = (username: string, password: string) => {
		return api({
			method: 'POST',
			url: '/connect/token',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: `grant_type=password&scope=${oAuthConfig.scope}&username=${username}&password=${password}&client_id=${oAuthConfig.clientId}&client_secret=${oAuthConfig.clientSecret}`,
			baseURL: oAuthConfig.issuer,
		}).then(async (response: any) => {
			const user = response.data
			setAuth(user)
			localStorage.setItem(AVANTEA_SESSION, JSON.stringify(user))
			history.push('/')
		})
	}

	function logout() {
		api({
			method: 'GET',
			url: CONFIG.LOGOUT,
		}).then(({ data }) => {
			localStorage.removeItem(AVANTEA_SESSION)
			history.push('/login')
			setAuth(null)
		})
	}

	return {
		login,
		logout,
		getAppConfig,
	}
}
