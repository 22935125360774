const CONFIG = {
	LOGOUT: '/api/account/logout',
	USERS: '/api/identity/users',
	ROLES: '/api/identity/roles',
	PERMISSION_MANAGEMENT: '/api/permission-management/permissions',
	COUNTRY: '/api/app/countries',
	CLASSES: '/api/app/reliability-classes',
	CLASSES_POST: '/api/app/safety-factors-configuration/country-reliability-class-safety-factors',
	SAFETY_FACTOR_POST: '/api/app/safety-factors-configuration/reliability-class-safety-factors',
	APP_CONFIG: '/api/abp/application-configuration',
	SAFETY_FACTORS: '/api/app/safety-factors-configuration',
	MATERIAL_INSULATIONS: '/api/app/material-insulations',
	FLANGES: '/api/app/material-flanges',
	BOLTS: '/api/app/material-bolts',
	BOLTS_QUALITIES: '/api/app/bolt-qualities',
	BOLTS_QUALITIES_DETAIL: '/api/app/bolt-qualities/yield-stress-by-temperature',
	LINEAR_ANCILLARIES: '/api/app/material-linear-ancillaries',
	LINEAR_ANCILLARY_WORKSTATION: '/api/app/material-linear-ancillaries/workstation-action',
	DISCRETE_ANCILLARIES: '/api/app/material-discrete-ancillaries',
	DISCRETE_ANCILLARY_WORKSTATION: '/api/app/material-discrete-ancillaries/workstation-action',
	PROFILES: '/api/app/material-profiles',
	CHIMNEY: '/api/app/chimney-definitions',
	CHIMNEY_UTILS: '/api/app/chimney-definitions-utils',
	TYPE_PROFILES: '/api/app/profile-types',
	COATINGS: '/api/app/material-coatings',
	STEEL: '/api/app/material-steels',
	STEEL_TYPES: '/api/app/material-steels/steel-types',
	STEEL_PREPARATION_TYPE: '/api/app/material-steels/steel-preparation-types',
	STEEL_ELASTICITY_BY_TEMPERATURE: '/api/app/material-steels/elasticity-by-temperature',
	STEEL_YIELD_STRESS_BY_TEMPERATURE: '/api/app/material-steels/yield-stress-by-temperature',
	STEEL_INTERNAL_CORROSION_ALLOWANCE: '/api/app/material-steels/internal-corrosion-allowance',
	STEEL_EXTERNAL_CORROSION_ALLOWANCE: '/api/app/material-steels/external-corrosion-allowance',
	STEEL_PROPERTIES: '/api/app/material-steels/steel-properties',
	TERRAIN_CATEGORIES: '/api/app/terrain-categories',
	REPORT_TEMPLATES: '/api/app/report-templates',
	WORKSTATIONS: '/api/app/workstations',
	MATERIAL_ACTION_SETTINGS: '/api/app/material-action-settings',
	ITEM_GROUPS: '/api/app/item-groups',
}
export default CONFIG
