export const getEnvVars = () => {
	return {
		apiUrl: process.env.REACT_APP_API,
		oAuthConfig: {
			clientId: process.env.REACT_APP_CLIENT_ID,
			clientSecret: process.env.REACT_APP_CLIENT_SECRET,
			issuer: process.env.REACT_APP_ISSUER,
			responseType: process.env.REACT_APP_RESPONSE_TYPE,
			scope: process.env.REACT_APP_SCOPE,
			requireHttps: process.env.REACT_APP_REQUIRE_HTTPS === 'true',
		},
		localization: {
			defaultResourceName: process.env.REACT_APP_RESOURCE_NAME,
		},
	}
}
