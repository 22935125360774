import { Modal } from 'antd'
import axios, { AxiosInstance } from 'axios'
import { getEnvVars } from 'Environment'
import { useRecoilValue } from 'recoil'
import { authAtom } from 'recoil/Login/atoms'
export { useAxiosWrapper }

function useAxiosWrapper() {
	const auth = useRecoilValue(authAtom)

	const { apiUrl } = getEnvVars()

	const axiosInstance: AxiosInstance = axios.create({
		baseURL: apiUrl,
		withCredentials: false,
	})

	axiosInstance.interceptors.request.use((request) => {
		if (auth && request && request.headers) {
			request.headers['Authorization'] = `Bearer ${auth?.access_token}`
		}

		return request
	})

	axiosInstance.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			const messageError = error?.response?.data?.error_description
				? error?.response.data.error_description
				: error?.response?.data?.error?.message || error.message
			const modal = Modal.error({})
			modal.update({
				title: 'Error',
				bodyStyle: { maxHeight: '80vh', overflowY: 'auto' },
				content: <pre>{messageError}</pre>,
				width: '60em',
			})
			return Promise.reject(error)
		}
	)

	return axiosInstance
}
