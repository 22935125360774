import { Progress } from 'antd'
import React, { lazy, Suspense, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { appConfigAtom } from 'recoil/Login/atoms'
import { isLoggedSelector } from 'recoil/Login/selectors'
import { useLoginRemote } from 'remote/loginHook'

const AppRoutes = lazy(() =>
	import('components/Routes/AppRoutes').then(({ AppRoutes: Routes }) => ({ default: Routes }))
)

const Routes = () => {
	const loginRemote = useLoginRemote()
	const isLogged = useRecoilValue(isLoggedSelector)
	const auth = useRecoilValue(appConfigAtom)

	useEffect(() => {
		const getConfig = async () => await loginRemote.getAppConfig()
		getConfig()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged])

	return auth.status !== 'loading' ? (
		<Suspense fallback={<Progress strokeLinecap="round" percent={99} strokeWidth={4} />}>
			<AppRoutes />
		</Suspense>
	) : (
		<Progress strokeLinecap="round" percent={99} strokeWidth={4} />
	)
}

export default Routes
