import React from 'react'
import { RecoilRoot } from 'recoil'
import './App.css'
import Routes from './components/Routes'

export default function App() {
	return (
		<RecoilRoot>
			<Routes />
		</RecoilRoot>
	)
}
